import { Box, IconButton, Theme } from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import React, { useMemo, VFC } from "react";
import { useUserContext } from "../../../context/UserContext";
// eslint-disable-next-line no-restricted-imports
import { UserOnboard } from "../../../reclaim-api/client";
import { ReclaimLogo } from "../../ReclaimLogo";
import { DoneStepBuffet } from "./DoneStepBuffet";
import { OnboardingTitle } from "./OnboardingTitle";

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    flex: 1,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    backgroundColor: theme.palette.common.white,
  },
  closeIcon: {
    position: "absolute",
    zIndex: theme.zIndex.modal + 1,
    right: theme.spacing(2),
    top: theme.spacing(2),
    [theme.breakpoints.up("md")]: {
      right: theme.spacing(3),
      top: theme.spacing(3),
    },
  },
}));

export type OnboardingBuffetProps = {
  onClose?: () => void; // If oboarding is dialog child
};

export const OnboardingBuffet: VFC<OnboardingBuffetProps> = ({ onClose }) => {
  const classes = useStyles();

  const [{ user }] = useUserContext();

  const onboardings = useMemo<UserOnboard | undefined>(() => user?.features.onboard, [user]);

  return (
    <Box className={classes.root}>
      {!!onClose && (
        <IconButton onClick={onClose} className={classes.closeIcon} size="large">
          <CloseRoundedIcon />
        </IconButton>
      )}

      <ReclaimLogo />

      <OnboardingTitle
        title="Get started with Reclaim"
        subtitle="Set up our full suite of tools to make your work-life more efficient"
      />
      <DoneStepBuffet onboardings={onboardings} />
    </Box>
  );
};
