import { Box, Typography } from "@mui/material";
import { ClassNameMap } from '@mui/styles';
import makeStyles from '@mui/styles/makeStyles';
import clsx from "clsx";
import React, { ReactNode, VFC } from "react";

const useStyles = makeStyles((theme) => ({
  root: {
    margin: theme.spacing(0, "auto", 3),
    maxWidth: 640,
    padding: theme.spacing(0, 2),
    textAlign: "center",
  },
  subtitle: {
    margin: theme.spacing(1, 3),
  },
}));

export type OnboardingTitleJSSClassKey = keyof ReturnType<typeof useStyles>;

export type OnboardingTitleProps = {
  classes?: Partial<ClassNameMap<OnboardingTitleJSSClassKey>>;
  className?: string;
  title: ReactNode;
  subtitle?: ReactNode;
};

export const OnboardingTitle: VFC<OnboardingTitleProps> = ({ className, classes: extClasses, title, subtitle }) => {
  const classes = useStyles({
    classes: extClasses,
  });

  return (
    <Box className={clsx(classes.root, className)}>
      <Typography component="h1" variant="h3">
        {title}
      </Typography>
      {!!subtitle && (
        <Typography variant="subtitle1" className={classes.subtitle}>
          {subtitle}
        </Typography>
      )}
    </Box>
  );
};
